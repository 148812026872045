<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all flow fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! flow identification                                                                                        ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col>
            <v-card outlined>
              <v-card-title>task group configuration</v-card-title>
              <v-col>
                <v-row>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field
                      :text="group.flow.name"
                      label="parent flow"
                      :go-to="readFlowPath"
                      :go-to-ref="group.flow.reference"
                    />
                  </v-col>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field
                      :text="group.reference"
                      label="group reference"
                    />
                    <w-text-field :text="group.name" label="group name" />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <w-textarea :text="group.description" label="group description" />
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col>
            <v-card class="elevation-6">
              <w-entity-list
                :columns="taskColumns"
                :items="group.tasks"
                :read-path="readTaskPath"
                read-ref="globalReference"
                title="task configurations"
              />
            </v-card>
          </v-col>
        </v-row>
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>

      <!-- <v-col>
        <v-row>
          <vue-mermaid-string v-if="showDiag" :value="diagram" />
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { VCard, VCardTitle, VCol, VContainer, VRow } from "vuetify/lib";

import api from "../../components/external/modules/shared/utils/api";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WTextarea from "../../components/external/modules/shared/views/WTextarea.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField.vue";
import path from "../../shared/paths";

export default {
  components: {
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VRow,
    WEntityList,
    WTextarea,
    WTextField,
  },

  data: () => ({
    group: {
      flow: {},
    },

    taskColumns: [
      { value: "name", text: "name", groupable: false },
      { value: "reference", text: "reference", groupable: false },
      { value: "manual", text: "manual", groupable: false },
      { value: "intervals", text: "intervals", groupable: false },
    ],

    readTaskPath: path.TASKCONFIG,
    readFlowPath: path.TASKFLOWCONFIG,
  }),

  created() {
    this.loadFlow();
  },

  methods: {
    loadFlow() {
      api
        .get("taskgroupconfig/read/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // setting the main object
            this.group = res.data.entity;
          } else {
            console.log(new Error("Failed searching the task flow config"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
</style>